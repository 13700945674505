import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    // Process the waitlist submission here
    console.log("Email submitted:", email);
    try {
      let res = await fetch("https://hooks.zapier.com/hooks/catch/14501530/32y2es4/", {
        method: "POST",
        body: JSON.stringify({
          email: email,
        }),
      });
      if (res.status !== 200) {
        console.log("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
    setEmail("");
  };

  return (
    <Container className="App">
      <Row>
        <Col>
          <h1 className="text-center mt-5 theme-title">Parallel Worlds</h1>
          <p className="text-center theme-subtitle">
            AI-enhanced fan translations of Chinese Wuxia novels
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Form onSubmit={handleSubmit} className="mt-5 theme-form">
            <Form.Group controlId="email">
              <Form.Label className="theme-label">Join our waitlist</Form.Label>
              <Form.Control
                className="theme-input"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
                required
              />
              <Form.Text className="text-muted theme-text">
                Be the first to access our collection of translated novels and AI-powered tools.
              </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3 w-100 theme-button">
              Join Waitlist
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default App;